import React, { useState } from "react";
import { CustomIconButton } from "./CustomIconButton";
import { ExitApplicationModal } from "./ExitApplicationModal";
import { DoorOpen } from "lucide-react";

export const ExitIconButton: React.FC = () => {
  const [showModal, setShowModal] = useState(false);

  const handleExit = () => {
    setShowModal(true);
  };

  return (
    <>
      <CustomIconButton icon={<DoorOpen />} handleClick={handleExit} />
      {showModal && <ExitApplicationModal onClose={() => setShowModal(false)} />}
    </>
  );
};
