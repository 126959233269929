import React from "react";
import { CustomIconButton } from "./CustomIconButton";
import { Share2Icon } from "lucide-react";
import configs from "../../../utils/configs";
// import whatsappIcon from "../../../react-components/icons/whatsapp.png";

export const ShareIconButton: React.FC = () => {
  const handleShare = async () => {
    if (navigator.share) {
      try {
        await navigator.share({
          title: configs.translation("tweet-text"),
          url: configs.translation("tweet-netlify-link")
        });
      } catch (error) {
        console.error("Error sharing:", error);
      }
    } else {
      alert("Web Share API not supported on this browser. You can copy the URL manually.");
    }
  };

  return (
    <CustomIconButton
      icon={
        <img src={"https://d1feqpg0v4zazs.cloudfront.net/whatsapp.png"} style={{ width: "22px", height: "22px" }} />
      }
      text="Share"
      handleClick={handleShare}
    />
  );
};
