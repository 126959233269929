import React, { useEffect, useState } from "react";
import styles from "./CoinCollectionElement.scss";
import CoinImage from "../../../assets/images/custom-icons/coin.png";

interface CoinCollectionElementProps {
  initialCoinsValue: number;
  onCoinsChange: (newValue: number) => void;
}

export const CoinCollectionElement: React.FC<CoinCollectionElementProps> = ({ initialCoinsValue, onCoinsChange }) => {
  const [coinsValue, setCoinsValue] = useState(initialCoinsValue);
  const [isAnimating, setIsAnimating] = useState(false);

  useEffect(() => {
    setCoinsValue(initialCoinsValue);
  }, [initialCoinsValue]);

  const handleCoinClick = () => {
    if (coinsValue > 0 && !isAnimating) {
      setIsAnimating(true);
      setCoinsValue(prev => prev - 1);
      onCoinsChange(coinsValue - 1);

      setTimeout(() => {
        setIsAnimating(false);
      }, 500); // Animation duration
    }
  };

  return (
    <div className={styles.coinCollectionElement}>
      <div className={styles.container}>
        <div className={`${styles.coin} ${isAnimating ? styles.animate : ""}`} onClick={handleCoinClick}>
          <img src={CoinImage} className={styles.coinImage} alt="Coin" />
          <span className={styles.coinValue}>
            {coinsValue}
            <span className={styles.coinLabel}>Coins</span>
          </span>
        </div>
      </div>
    </div>
  );
};
