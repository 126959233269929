import { useState, useEffect, useCallback } from "react";

export const LocalStorageKeys = {
  COINS: "coins",
  TUTORIAL_COMPLETED: "isTutorialCompleted",
  CART: "cart"
} as const;

export type LocalStorageKey = keyof typeof LocalStorageKeys;

export function useLocalStorage<T>(key: LocalStorageKey, initialValue: T): [T, (value: T | ((val: T) => T)) => void] {
  const [storedValue, setStoredValue] = useState<T>(() => {
    if (typeof window === "undefined") {
      return initialValue;
    }
    try {
      const item = window.localStorage.getItem(LocalStorageKeys[key]);
      return item ? JSON.parse(item) : initialValue;
    } catch (error) {
      console.error(`Error reading localStorage key "${key}":`, error);
      return initialValue;
    }
  });

  const setValue = useCallback(
    (value: T | ((val: T) => T)) => {
      try {
        const valueToStore = value instanceof Function ? value(storedValue) : value;
        setStoredValue(valueToStore);
        if (typeof window !== "undefined") {
          window.localStorage.setItem(LocalStorageKeys[key], JSON.stringify(valueToStore));
        }
      } catch (error) {
        console.error(`Error setting localStorage key "${key}":`, error);
      }
    },
    [key, storedValue]
  );

  useEffect(() => {
    const handleStorageChange = (e: StorageEvent) => {
      if (e.key === LocalStorageKeys[key]) {
        try {
          const newValue = e.newValue ? JSON.parse(e.newValue) : null;
          if (newValue !== null) {
            setStoredValue(newValue);
          }
        } catch (error) {
          console.error(`Error parsing localStorage key "${key}":`, error);
        }
      }
    };

    window.addEventListener("storage", handleStorageChange);
    return () => window.removeEventListener("storage", handleStorageChange);
  }, [key]);

  return [storedValue, setValue];
}
