import React from "react";
import styles from "./CustomIconButton.scss";

interface CustomIconButtonProps {
  icon: React.ReactNode;
  text?: string;
  handleClick: () => void;
  showTextOnMobile?: boolean;
  style?: React.CSSProperties;
}

export const CustomIconButton: React.FC<CustomIconButtonProps> = ({
  icon,
  text,
  handleClick,
  showTextOnMobile = false,
  style
}) => {
  return (
    <button className={styles.customIconButton} style={style} onClick={handleClick}>
      {icon}
      {text && showTextOnMobile ? <span className={styles.buttonText}>{text}</span> : <></>}
    </button>
  );
};
