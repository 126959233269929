//@ts-nocheck
import React, { useEffect, useState } from "react";
import styles from "./StepsToExplore.scss";
import { Building2, Cloud, Radio, Video, Gamepad, Camera, Users } from "lucide-react";
import configs from "../../../utils/configs";
import { useRoomContext } from "../contexts/RoomContext";
import { changeHub } from "../../../change-hub";
import { getCurrentHubId } from "../../../utils/hub-utils";
import { hubUrl } from "../../../utils/phoenix-utils";

// interface StepsToExploreProps {
//   setIsStepsToExploreOpen: (isOpen: boolean) => void;
// }

export default function StepsToExplore() {
  const [isMounted, setIsMounted] = useState(false);

  const { showStepsToExplore, toggleStepsToExplore } = useRoomContext();
  const [currentStep, setCurrentStep] = useState(0);
  useEffect(() => {
    setIsMounted(true);
    // const timer = setTimeout(() => {
    //   setIsStepsToExploreOpen(false);
    // }, 2000);

    // return () => clearTimeout(timer);
  }, []);

  if (!isMounted) return null;

  //   const stepsToExploreData = JSON.parse(configs.translation("steps-to-explore"));
  const stepsToExploreData = JSON.parse(configs.translation("steps-to-explore"));
  const stepsToExploreDataMobile = JSON.parse(configs.translation("steps-to-explore-mobile"));
  console.log("step to explore", stepsToExploreData);
  //@ts-ignore
  //   const steps = stepsToExploreData.map((stepData, index) => ({
  //     icon: React.createElement([Building2, Cloud, Radio, Video, Gamepad, Camera, Users][index], {
  //       className: styles.icon
  //     }),
  //     title: stepData.title,
  //     description: stepData.description
  //   }));

  const steps = stepsToExploreData.map((stepData, index) => (
    <img key={index} src={stepData} alt="modal" className={styles.stepsContainerDesktop} />
  ));
  const stepsMobile = stepsToExploreDataMobile.map((stepData, index) => (
    <img key={index} src={stepData} alt="modal" className={styles.stepsContainerMobile} />
  ));

  const handleNext = () => {
    setCurrentStep(prevStep => prevStep + 1);
    if (currentStep === steps.length - 1) {
      toggleStepsToExplore(false);
    }
  };

  return (
    <div className={styles.view}>
      <div className={styles.container}>
        {/* <h1 className={styles.header}>Steps to Explore {configs.translation("app-name")}</h1> */}
        <div
          onClick={() => {
            handleNext();
          }}
          className={styles.stepsContainer}
        >
          <div className={styles.stepsContainerDesktop}>{steps[currentStep]}</div>
          <div className={styles.stepsContainerMobile}>{stepsMobile[currentStep]}</div>
        </div>
      </div>
    </div>
  );
}
