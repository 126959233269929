import React, { useEffect } from "react";
import styles from "./ExitApplicationModal.scss";

interface ExitApplicationModalProps {
  onClose: () => void;
}

export const ExitApplicationModal: React.FC<ExitApplicationModalProps> = ({ onClose }) => {
  useEffect(() => {
    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      event.preventDefault();
      event.returnValue = "";
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  const handleExit = () => {
    window.removeEventListener("beforeunload", () => {});
    window.location.href = "https://abpverse.com"; // Replace with your desired exit URL
  };

  return (
    <div className={styles.modalOverlay}>
      <div className={styles.modalContent}>
        <h2 className={styles.title}>Are you sure?</h2>
        <p className={styles.description}>If you click "YES", you'll leave Prayagraj Mahakumbh 2025 Mela!</p>
        <div className={styles.buttonContainer}>
          <button className={styles.exitButton} onClick={handleExit}>
            YES
          </button>
          <button className={styles.cancelButton} onClick={onClose}>
            NO
          </button>
        </div>
      </div>
    </div>
  );
};
