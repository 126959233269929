import React from "react";
import PropTypes from "prop-types";
import styles from "./ProgressSpinner.scss";

export function ProgressSpinner({ progress = 0, size = 44 }) {
  // Calculate the circumference of the circle
  const radius = (size - 4) / 2;
  const circumference = 2 * Math.PI * radius;

  // Calculate the dash offset based on progress
  const strokeDashoffset = circumference - (progress / 100) * circumference;

  return (
    <div className={styles.progressSpinner} style={{ width: size, height: size }}>
      <svg width={size} height={size} viewBox={`0 0 ${size} ${size}`}>
        {/* Background circle */}
        <circle className={styles.background} cx={size / 2} cy={size / 2} r={radius} fill="none" strokeWidth="4" />
        {/* Progress circle */}
        <circle
          className={styles.progress}
          cx={size / 2}
          cy={size / 2}
          r={radius}
          fill="none"
          strokeWidth="4"
          strokeDasharray={circumference}
          strokeDashoffset={strokeDashoffset}
          transform={`rotate(-90 ${size / 2} ${size / 2})`}
        />
      </svg>
      <div className={styles.label}>{Math.round(progress)}%</div>
    </div>
  );
}

ProgressSpinner.propTypes = {
  progress: PropTypes.number,
  size: PropTypes.number
};
