import React, { forwardRef } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import styles from "./ToolbarButton.scss";

export const presets = ["basic", "transparent", "primary", "accent1", "accent2", "accent3", "accent4", "accent5"];

export const statusColors = ["active", "inactive", "warning", "error", "disabled"];

export const ToolbarButton = forwardRef(
  (
    {
      preset,
      className,
      iconContainerClassName,
      children,
      icon,
      label,
      title,
      selected,
      statusColor,
      disabled,
      onClick,
      ...rest
    },
    ref
  ) => {
    return (
      <button
        ref={ref}
        className={classNames(styles.toolbarButton, styles[preset], { [styles.selected]: selected }, className)}
        disabled={disabled}
        title={title || label}
        onClick={onClick}
        {...rest}
      >
        <div
          className={classNames(styles.iconContainer, iconContainerClassName)}
          disabled={disabled}
          aria-hidden="true"
        >
          {icon}
          {statusColor && <div className={classNames(styles.statusIndicator, styles["status-" + statusColor])} />}
          {children}
        </div>
        {label && <span className={styles.label}>{label}</span>}
      </button>
    );
  }
);

ToolbarButton.propTypes = {
  icon: PropTypes.node,
  label: PropTypes.node,
  selected: PropTypes.bool,
  preset: PropTypes.oneOf(presets),
  statusColor: PropTypes.oneOf(statusColors),
  className: PropTypes.string,
  iconContainerClassName: PropTypes.string,
  children: PropTypes.node,
  disabled: PropTypes.bool,
  title: PropTypes.node,
  onClick: PropTypes.func
};

ToolbarButton.defaultProps = {
  preset: "basic",
  disabled: false
};

ToolbarButton.displayName = "ToolbarButton";
