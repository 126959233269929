import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import styles from "./RoomLayout.scss";
import { Toolbar } from "./Toolbar";
import configs from "../../utils/configs";
import SelfieModalContainer from "../room/custom-components/SelfieModalContainer";
import ProfileSwipeModal from "../room/custom-components/ProfileSwipeModal";
import { IframeContainer } from "../room/custom-components/IframeContainer";
import VideoPlayingElement from "../room/custom-components/VideoPlayingElement";
import StepsToExplore from "../room/custom-components/StepsToExplore";
import { CoinCollectionElement } from "../room/custom-components/CoinCollectionElement";
import { AudioPlayer } from "../room/custom-components/AudioPlayer";
import { RoomProvider, useRoomContext } from "../room/contexts/RoomContext";
import { CustomNavigationBar } from "../room/custom-components/CustomNavigationBar";
import { ExitIconButton } from "../room/custom-components/ExitIconButton";
import { ShareIconButton } from "../room/custom-components/ShareIconButton";
// import { ShareWhatsappIconButton } from "../room/custom-components/ShareWhatsappIconButton";

interface RoomLayoutProps {
  className?: string;
  viewportClassName?: string;
  sidebar?: React.ReactNode;
  sidebarClassName?: string;
  toolbarLeft?: React.ReactNode;
  toolbarCenter?: React.ReactNode;
  toolbarRight?: React.ReactNode;
  toolbarClassName?: string;
  modal?: React.ReactNode;
  viewport: React.ReactNode;
  objectFocused?: boolean;
  streaming?: boolean;
  entered?: boolean;
  scene: any;
  viewportRef: any;
}

const RoomLayoutContent: React.FC<RoomLayoutProps> = ({
  className,
  entered,
  viewportClassName,
  sidebar,
  sidebarClassName,
  toolbarLeft,
  toolbarCenter,
  toolbarRight,
  toolbarClassName,
  modal,
  viewport,
  objectFocused,
  streaming,
  viewportRef,
  ...rest
}) => {
  const {
    isIframeVisible,
    iframeUrl,
    isProfileSwipeModalOpen,
    audioPlaying,
    audioUrl,
    screenshotImage,
    isVideoPlaying,
    isSelfieOpen,
    videoUrl,
    videoTitle,
    coinsValue,
    toggleIframe,
    toggleVideoPlaying,
    endAudioPlaying,
    setCoinsValue,
    showStepsToExplore,
    toggleStepsToExplore,
    closeSelfie
  } = useRoomContext();

  const qs = new URLSearchParams(location.search);
  //   console.log("entered value is ", entered);

  //   useEffect(() => {
  //     console.log("entered value is ", entered);
  //     // if (entered == true) {
  //     //   toggleStepsToExplore();
  //     // }
  //     // return () => {
  //     //     cleanup
  //     // }
  //   }, [entered]);

  return (
    <div
      ref={viewportRef}
      className={classNames(styles.roomLayout, { [styles.objectFocused]: objectFocused }, className)}
      {...rest}
    >
      <div className={styles.modalContainer}>
        <CustomNavigationBar
          leftChildren={
            <>
              <img
                src="https://d1feqpg0v4zazs.cloudfront.net/ABP+Mahakumbh+2025+Frame.png"
                alt="Logo"
                className={styles.logoImage}
              />
            </>
          }
          centerChildren={<></>}
          rightChildren={
            <>
              <>{coinsValue > 0 && <CoinCollectionElement onCoinsChange={() => {}} initialCoinsValue={coinsValue} />}</>
              <>{<ShareIconButton />}</>

              <>{<ExitIconButton />}</>
            </>
          }
        />
        {isSelfieOpen && <SelfieModalContainer onClose={closeSelfie} />}
        {showStepsToExplore && <StepsToExplore />}
        {isProfileSwipeModalOpen && <ProfileSwipeModal setIsProfileSwipeModalOpen={() => {}} />}
        {isIframeVisible && (
          <IframeContainer toggleIframeOpen={toggleIframe} src={iframeUrl ?? ""} isIframeForMobile={false} />
        )}
        {isVideoPlaying && (
          <VideoPlayingElement toggleVideoPlaying={toggleVideoPlaying} videoSrc={videoUrl} videoTitle={videoTitle} />
        )}
        {/* {coinsValue > 0 && <CoinCollectionElement onCoinsChange={() => {}} initialCoinsValue={coinsValue} />} */}
      </div>

      {/* {audioPlaying && <AudioPlayer audioUrl={audioUrl} onEnded={endAudioPlaying} />} */}

      {sidebar && <div className={classNames(styles.sidebar, sidebarClassName)}>{sidebar}</div>}
      <div className={classNames(styles.modalContainer, styles.viewport)}>{modal}</div>
      {(((toolbarLeft || toolbarCenter || toolbarRight) && configs.feature("show-toolbar")) || qs.get("inspect")) && (
        <Toolbar
          className={classNames(styles.main, styles.toolbar, toolbarClassName)}
          left={toolbarLeft}
          center={toolbarCenter}
          right={toolbarRight}
        />
      )}
      <div className={classNames(styles.main, styles.viewport, { [styles.streaming]: streaming }, viewportClassName)}>
        {viewport}
      </div>
    </div>
  );
};

export const RoomLayout: React.FC<RoomLayoutProps> = props => (
  <RoomProvider scene={props.scene}>
    <RoomLayoutContent {...props} />
  </RoomProvider>
);

RoomLayout.propTypes = {
  className: PropTypes.string,
  viewportClassName: PropTypes.string,
  entered: PropTypes.bool,
  //   toggleIFrame: PropTypes.func,
  sidebar: PropTypes.node,
  sidebarClassName: PropTypes.string,
  toolbarLeft: PropTypes.node,
  toolbarCenter: PropTypes.node,
  toolbarRight: PropTypes.node,
  toolbarClassName: PropTypes.string,
  modal: PropTypes.node,
  viewport: PropTypes.node,
  objectFocused: PropTypes.bool,
  streaming: PropTypes.bool,
  viewportRef: PropTypes.any,
  scene: PropTypes.any.isRequired
};
