import { useEffect, useState, useRef, useCallback } from "react";

export function useRandomMessageTransition(messages, transitionDelay = 5000) {
  const messageTimeoutRef = useRef();
  const [index, setIndex] = useState(Math.round(Math.random() * (messages.length - 1)));
  const [isTransitioning, setIsTransitioning] = useState(false);

  const queueNextMessage = useCallback(() => {
    messageTimeoutRef.current = setTimeout(() => {
      setIsTransitioning(true);

      // Wait for fade out
      setTimeout(() => {
        setIndex(currentIndex => (currentIndex + 1) % messages.length);
        setIsTransitioning(false);
        queueNextMessage();
      }, 300);
    }, transitionDelay);
  }, [setIndex, messages, transitionDelay]);

  useEffect(() => {
    queueNextMessage();

    return () => {
      clearTimeout(messageTimeoutRef.current);
    };
  }, [queueNextMessage]);

  return {
    message: messages[index],
    isTransitioning
  };
}
