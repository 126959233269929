import React from 'react';
import styles from "./CustomNavigationBar.scss";

interface CustomNavigationBarProps {
  leftChildren?: React.ReactNode;
  centerChildren?: React.ReactNode;
  rightChildren?: React.ReactNode;
}

export const CustomNavigationBar: React.FC<CustomNavigationBarProps> = ({
  leftChildren,
  centerChildren,
  rightChildren
}) => {
  return (
    <nav className={styles.customNavigationBar}>
      <div className={styles.leftSection}>{leftChildren}</div>
      <div className={styles.centerSection}>{centerChildren}</div>
      <div className={styles.rightSection}>{rightChildren}</div>
    </nav>
  );
};

