import React from "react";
import PropTypes from "prop-types";
import { Transition } from "react-transition-group";
import styles from "./LoadingScreenLayout.scss";
import { Column } from "../layout/Column";
// import { AppLogo } from "../misc/AppLogo";
import { ProgressSpinner } from "./ProgressSpinner";

export function LoadingScreenLayout({ center, bottom, progress }) {
  // Calculate progress based on loading state

  return (
    <div className={styles.loadingScreenLayout}>
      <Column center padding gap="lg" className={styles.center}>
        {/* <AppLogo className={styles.logo} /> */}
        <ProgressSpinner progress={progress} size={60} />
        <Transition in={true} timeout={300}>
          {state => <div className={`${styles.message} ${styles[state]}`}>{center}</div>}
        </Transition>
      </Column>
      {bottom && (
        <Column center className={styles.bottom}>
          {bottom}
        </Column>
      )}
    </div>
  );
}

LoadingScreenLayout.propTypes = {
  center: PropTypes.node,
  bottom: PropTypes.node,
  progress: PropTypes.any
};
