import React, { useState, useCallback, useEffect } from "react";
import styles from "./SelfieModalContainer.scss";
import configs from "../../../utils/configs";

import { ReactComponent as WhatsappIcon } from "../../icons/Whatsapp.svg";
import { ReactComponent as TwitterIcon } from "../../icons/X.svg";
import { ReactComponent as InstagramIcon } from "../../icons/Instagram.svg";
import { CustomIconButton } from "./CustomIconButton";

const tweetButtonText = `https://x.com/intent/tweet?text=${encodeURIComponent(
  configs.translation("tweet-text")
)}&url=${encodeURIComponent(configs.translation("tweet-netlify-link"))}`;
const whatsappButtonUrl = `https://api.whatsapp.com/send?text=${encodeURIComponent(configs.translation("tweet-text"))}`;
// const instagramShareUrl = `https://www.instagram.com/share?url=${encodeURIComponent(
//   configs.translation("tweet-netlify-link")
// )}`;

const instagramShareUrl = `instagram://story-camera`;

interface SelfieModalContainerProps {
  onClose: () => void;
}

interface ToastProps {
  message: string;
  type: "success" | "error";
}

export const SelfieModalContainer: React.FC<SelfieModalContainerProps> = ({ onClose }) => {
  const [isVideoPlaying, setIsVideoPlaying] = useState<boolean>(true);
  const [isDownloading, setIsDownloading] = useState<boolean>(false);
  const [toast, setToast] = useState<ToastProps | null>(null);

  const showToast = useCallback((message: string, type: "success" | "error") => {
    setToast({ message, type });
  }, []);

  useEffect(() => {
    if (toast) {
      const timer = setTimeout(() => {
        setToast(null);
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [toast]);

  const handleImageClick = useCallback(() => {
    setIsVideoPlaying(true);
    // showToast("The video is now playing.", "success");
  }, [showToast]);

  const handleDownload = useCallback(async () => {
    setIsDownloading(true);
    try {
      const response = await fetch(configs.translation("selfie-file"));
      if (!response.ok) throw new Error("Failed to fetch the file");

      const blob = await response.blob();
      const url = URL.createObjectURL(blob);

      const link = document.createElement("a");
      link.href = url;
      link.download = "ABPverse Mahakumbh 2025.mp4";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      URL.revokeObjectURL(url);

      showToast("Your Astha Snan Video has been downloaded.", "success");
    } catch (error) {
      console.error("Error downloading the file:", error);
      showToast("There was an error downloading your video. Please try again.", "error");
    } finally {
      setIsDownloading(false);
    }
  }, [showToast]);

  const handleShare = useCallback(
    (platform: "whatsapp" | "twitter" | "instagram") => {
      showToast(`Opening ${platform} to share your Astha Snan Video.`, "success");
    },
    [showToast]
  );

  return (
    <div className={styles.modalOverlay}>
      <div className={styles.modalContent}>
        <button className={styles.closeButton} onClick={onClose}>
          <span className={styles.closeIcon}>×</span>
        </button>

        <div className={styles.imageContainer}>
          {!isVideoPlaying ? (
            <img
              onClick={handleImageClick}
              src={configs.translation("selfie-thumbnail") || "/placeholder.svg"}
              alt="Selfie preview"
              className={styles.selfieImage}
            />
          ) : (
            <video
              src={configs.translation("selfie-file")}
              className={styles.selfieImage}
              autoPlay
              muted
              playsInline
              loop
            />
          )}

          <div className={styles.downloadButton}>
            <div className={styles.customButton}>
              <CustomIconButton
                style={{ width: "100%" }}
                handleClick={handleDownload}
                icon={<span className={styles.downloadIcon}>↓</span>}
                text={isDownloading ? "Downloading..." : "Download your Astha Snan Video"}
                showTextOnMobile={true}
              ></CustomIconButton>
            </div>
          </div>
        </div>

        <div className={styles.shareContainer}>
          <a href={whatsappButtonUrl} className={styles.shareButton} onClick={() => handleShare("whatsapp")}>
            <WhatsappIcon />
          </a>
          <a href={tweetButtonText} className={styles.shareButton} onClick={() => handleShare("twitter")}>
            <TwitterIcon />
          </a>
          <a href={instagramShareUrl} className={styles.shareButton} onClick={() => handleShare("instagram")}>
            <InstagramIcon />
          </a>
        </div>
      </div>
      {toast && <div className={`${styles.toast} ${styles[toast.type]}`}>{toast.message}</div>}
    </div>
  );
};

export default SelfieModalContainer;
