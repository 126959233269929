import React from "react";
import PropTypes from "prop-types";
import { LoadingScreenLayout } from "../layout/LoadingScreenLayout";
import { useRandomMessageTransition } from "./hooks/useRandomMessageTransition";
import styles from "../layout/LoadingScreenLayout.scss";

export function LoadingScreen({ message, infoMessages, progress }) {
  const infoMessage = useRandomMessageTransition(infoMessages);

  return (
    <LoadingScreenLayout
      center={
        <div className={styles.centerContent}>
          {message}
          {/* <b clssName={styles.message}>{message}</b> */}
        </div>
      }
      bottom={
        <div className={styles.bottomContent}>
          {/* {JSON.stringify(infoMessage).toString()} */}
          {infoMessage && infoMessage.message && (
            <>
              <h3>{infoMessage?.message.heading}</h3>
              <p>{infoMessage?.message.message}</p>
            </>
          )}
        </div>
      }
      progress={progress}
    />
  );
}

LoadingScreen.propTypes = {
  message: PropTypes.node,
  infoMessages: PropTypes.arrayOf(
    PropTypes.shape({
      heading: PropTypes.node.isRequired,
      message: PropTypes.node.isRequired
    })
  ),
  progress: PropTypes.number
};

LoadingScreen.defaultProps = {
  infoMessages: [],
  progress: 0
};
