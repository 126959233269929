import React, { createContext, useContext } from "react";
import { useRoomState, RoomState } from "../hooks/useRoomState";

interface RoomContextType extends RoomState {
  toggleIframe: () => void;
  captureSelfie: (value?: boolean) => void;
  toggleVideoPlaying: () => void;
  endAudioPlaying: () => void;
  setCoinsValue: (value: string) => void;
  toggleStepsToExplore: (value?: boolean) => void;
  closeSelfie: () => void;
}

const RoomContext = createContext<RoomContextType | undefined>(undefined);

export const useRoomContext = () => {
  const context = useContext(RoomContext);
  if (!context) {
    throw new Error("useRoomContext must be used within a RoomProvider");
  }
  return context;
};

export const RoomProvider: React.FC<{ children: React.ReactNode; scene: any }> = ({ children, scene }) => {
  const roomState = useRoomState(scene);

  return <RoomContext.Provider value={roomState}>{children}</RoomContext.Provider>;
};
