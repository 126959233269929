import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useIntl } from "react-intl";
import { LoadingScreen } from "./LoadingScreen";
import { useRoomLoadingState } from "./hooks/useRoomLoadingState";

const infoMessages = [
  {
    heading: "Once-in-144-Years Event",
    message:
      "This Maha Kumbh Mela marks the completion of 12 Kumbh Mela cycles, making it a once-in-144-years occurrence."
  },
  {
    heading: "Massive Attendance",
    message:
      "An estimated 400 million pilgrims are expected to attend over the 44-day period, making it one of the largest religious gatherings globally."
  },
  {
    heading: "Sacred Confluence",
    message:
      "The event takes place at the Triveni Sangam, the confluence of the Ganga, Yamuna, and the mythical Saraswati rivers, considered highly auspicious for spiritual cleansing."
  },
  {
    heading: "Shahi Snan (Royal Baths)",
    message:
      "There are six significant bathing dates known as 'Amrit Snan' or 'Shahi Snan' during the Mela, attracting millions of devotees seeking spiritual purification."
  },
  {
    heading: "Advanced Security Measures",
    message:
      "Over 2,700 AI-enabled cameras and a multi-layered security force will be deployed to ensure the safety of attendees."
  },
  {
    heading: "Environmental Initiatives",
    message:
      "A strong emphasis on cleanliness is maintained, with one sweeper for every ten toilets and a dedicated monitoring system to ensure hygiene across the Mela grounds."
  },
  {
    heading: "Cultural Activities",
    message:
      "The Mela features spiritual discourses, folk performances, and mythological dramatizations, enriching the spiritual atmosphere."
  },
  {
    heading: "Global Recognition",
    message:
      "In 2017, the Kumbh Mela was inscribed on UNESCO's Representative List of Intangible Cultural Heritage of Humanity."
  },
  {
    heading: "Economic Impact",
    message:
      "With an estimated budget of ₹6,382 crore allocated for infrastructure and services, the Mela is expected to generate substantial economic benefits for the region."
  },
  {
    heading: "Health Services",
    message:
      "Comprehensive medical facilities, including a 100-bed temporary central hospital, are being set up to ensure the well-being of attendees."
  }
];
export function LoadingScreenContainer({ onLoaded, scene }) {
  const intl = useIntl();

  const { loading, message, objectCount, loadedCount, environmentLoaded, networkConnected, dialogConnected } =
    useRoomLoadingState(scene);

  const calculateProgress = () => {
    if (objectCount === 0) {
      let progress = 0;
      if (environmentLoaded) progress += 33;
      if (networkConnected) progress += 33;
      if (dialogConnected) progress += 34;
      return progress;
    }

    const objectProgress = (loadedCount / Math.max(objectCount, 1)) * 80;

    let additionalProgress = 0;
    if (environmentLoaded) additionalProgress += 6;
    if (networkConnected) additionalProgress += 7;
    if (dialogConnected) additionalProgress += 7;
    // console.log(
    //   "loading objects",
    //   loading,
    //   message,
    //   objectCount,
    //   loadedCount,
    //   environmentLoaded,
    //   networkConnected,
    //   dialogConnected
    // );
    return Math.min(Math.round(objectProgress + additionalProgress), 100);
  };

  useEffect(() => {
    calculateProgress();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading, message, objectCount, loadedCount, environmentLoaded, networkConnected, dialogConnected]);

  useEffect(() => {
    if (!loading) {
      onLoaded();
    }
  }, [loading, onLoaded]);

  return <LoadingScreen message={message} infoMessages={infoMessages} progress={calculateProgress()} />;
}

LoadingScreenContainer.propTypes = {
  scene: PropTypes.object.isRequired,
  onLoaded: PropTypes.func.isRequired
};
