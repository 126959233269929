import { useState, useEffect, useCallback } from "react";
import axios from "axios";
import { LocalStorageKeys, useLocalStorage } from "./useLocalStorage";
import configs from "../../../utils/configs";

export interface RoomState {
  isIframeVisible: boolean;
  iframeUrl: string | undefined;
  isProfileSwipeModalOpen: boolean;
  audioPlaying: boolean;
  tweetButtonText: string;
  whatsappButtonUrl: string;
  audioUrl: string | undefined;
  screenshotImage: string | undefined;
  isVideoPlaying: boolean;
  isSelfieOpen: boolean;
  videoUrl: string;
  videoTitle: { question: string; answerText: string };
  coinsValue: number;
  showStepsToExplore: boolean;
  stepToExploreStep: number;
}

export const useRoomState = (scene: any) => {
  const [state, setState] = useState<RoomState>({
    isIframeVisible: false,
    iframeUrl: undefined,
    isProfileSwipeModalOpen: false,
    audioPlaying: false,
    audioUrl: undefined,
    screenshotImage: undefined,
    isVideoPlaying: false,
    tweetButtonText: "",
    whatsappButtonUrl: "",
    videoUrl: "",
    isSelfieOpen: false,
    videoTitle: { question: "", answerText: "" },
    coinsValue: 0,
    showStepsToExplore: true,
    stepToExploreStep: 0
  });


  const [coinsValue, setCoinsValue] = useLocalStorage("COINS", "0");

  const updateState = useCallback((newState: Partial<RoomState>) => {
    setState(prevState => ({ ...prevState, ...newState }));
  }, []);

  const toggleIframe = useCallback(() => {
    updateState({ isIframeVisible: !state.isIframeVisible });
  }, [state.isIframeVisible]);

  const toggleStepsToExplore = useCallback((newValue: boolean) => {
    updateState({ showStepsToExplore: newValue ? newValue : !state.showStepsToExplore });
  }, []);

  const toggleVideoPlaying = useCallback(() => {
    updateState({ isVideoPlaying: !state.isVideoPlaying });
  }, [state.isVideoPlaying]);

  const openTheIframe = useCallback((data: any) => {
    updateState({ isIframeVisible: true, iframeUrl: data.detail });
  }, []);

  const openTheFrameApi = useCallback(async (data: any) => {
    const finalData = await axios.get(data.detail.url);
    const otherData = await axios.get(data.detail.type);
    updateState({ isIframeVisible: false, iframeUrl: finalData.data });
  }, []);

  const profileSwipeOpen = useCallback(() => {
    updateState({ isProfileSwipeModalOpen: true });
  }, []);

  const play2dAudio = useCallback((data: any) => {
    updateState({ audioPlaying: true, audioUrl: data.detail });
  }, []);

  const endAudioPlaying = useCallback(() => {
    updateState({ audioPlaying: false });
  }, []);

  const modalBeforeVideo = useCallback((data: any) => {
    updateState({
      isVideoPlaying: true,
      videoTitle: { question: data.detail.question, answerText: data.detail.answerText ?? "OK" },
      videoUrl: data.detail.videoUrl
    });
  }, []);

  const captureSelfie = useCallback((value: boolean) => {
    // const qs = new URLSearchParams(location.search);
    updateState({
      isSelfieOpen: true,
      screenshotImage: configs.translation("selfie-file")
    });
  }, []);

  const closeSelfie = useCallback(() => {
    // const qs = new URLSearchParams(location.search);
    updateState({
      isSelfieOpen: false,
      tweetButtonText: "",
      whatsappButtonUrl: ""
    });
  }, []);

  const valueUpdate = useCallback(
    (data: any) => {
      let value = parseInt(localStorage.getItem(data.detail.name) || "0");
      value++;
      localStorage.setItem(data.detail.name, value.toString());
      setCoinsValue(value.toString());
    },
    [setCoinsValue]
  );

  useEffect(() => {
    const eventListeners = [
      { name: "profile_swipe", handler: profileSwipeOpen },
      { name: "iframe_open", handler: openTheIframe },
      { name: "iframe_api", handler: openTheFrameApi },
      { name: "modal_before_video", handler: modalBeforeVideo },
      { name: "play_2d_audio", handler: play2dAudio },
      { name: "capture_selfie", handler: captureSelfie },
      { name: "value_update", handler: valueUpdate },
      { name: "2d_popup", handler: play2dAudio },
      { name: "read_bot_text", handler: play2dAudio },
      { name: "video_full_screen", handler: modalBeforeVideo }
    ];

    eventListeners.forEach(({ name, handler }) => {
      if (!scene) return;
      scene.addEventListener(name, handler);
    });

    return () => {
      eventListeners.forEach(({ name, handler }) => {
        if (!scene) return;
        scene.removeEventListener(name, handler);
      });
    };
  }, [
    scene,
    profileSwipeOpen,
    openTheIframe,
    openTheFrameApi,
    modalBeforeVideo,
    play2dAudio,
    captureSelfie,
    valueUpdate
  ]);

  return {
    ...state,
    coinsValue: parseInt(coinsValue),
    toggleIframe,
    toggleVideoPlaying,
    endAudioPlaying,
    setCoinsValue,
    toggleStepsToExplore,
    captureSelfie,
    closeSelfie
  };
};
