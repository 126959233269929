import React from "react";
import PropTypes from "prop-types";
import { Button, AcceptButton } from "../input/Button";
import styles from "./AvatarSettingsContent.scss";
import { TextInputField } from "../input/TextInputField";
import { Column } from "../layout/Column";
import { FormattedMessage } from "react-intl";
// import { InfoCircle } from "@mozilla/lilypad-ui";
import { AppLogo } from "../misc/AppLogo";
import configs from "../../utils/configs";

function CompanyDetails() {
  return (
    <div className={styles.companyDetails}>
      <AppLogo className={styles.logo} />
      <h1 className={styles.companyName}>{configs.translation("app-name")}</h1>
      <div className={styles.companyDescription}>{configs.translation("app-description")}</div>
    </div>
  );
}

export function AvatarSettingsContent({
  displayName,
  pronouns,
  displayNameInputRef,
  pronounsInputRef,
  disableDisplayNameInput,
  onChangeDisplayName,
  onChangePronouns,
  avatarPreview,
  displayNamePattern,
  pronounsPattern,
  onChangeAvatar,
  ...rest
}) {
  return (
    <div className={styles.parent}>
      <div className={styles.container}>
        <CompanyDetails />
        <Column as="form" className={styles.content} {...rest}>
          <h2 className={styles.formTitle}>
            <FormattedMessage id="avatar-settings-content.form-title" defaultMessage="Set Up Your Avatar" />
          </h2>
          <TextInputField
            disabled={disableDisplayNameInput}
            label={<FormattedMessage id="avatar-settings-content.display-name-label" defaultMessage="Display Name" />}
            value={displayName}
            pattern={displayNamePattern}
            spellCheck="false"
            required
            onChange={onChangeDisplayName}
            description={
              <div className={styles.displayNameDescription}>
                <FormattedMessage
                  id="avatar-settings-content.display-name-description"
                  defaultMessage="Alphanumerics, hyphens, underscores, and tildes. At least 3 characters, no more than 32"
                />
              </div>
            }
            ref={displayNameInputRef}
          />
          <TextInputField
            label={
              <FormattedMessage id="avatar-settings-content.pronouns-label" defaultMessage="Pronouns (optional)" />
            }
            value={pronouns}
            pattern={pronounsPattern}
            spellCheck="false"
            onChange={onChangePronouns}
            ref={pronounsInputRef}
          />
          <div className={styles.avatarPreviewContainer}>
            {avatarPreview || <div className={styles.avatarPlaceholder} />}
            <Button type="button" preset="basic" onClick={onChangeAvatar}>
              <FormattedMessage id="avatar-settings-content.change-avatar-button" defaultMessage="Change Avatar" />
            </Button>
          </div>
          <div className={styles.acceptButton}>
            <AcceptButton preset="accept" type="submit">
              <FormattedMessage id="avatar-settings-content.submit-button" defaultMessage="Get Started" />
            </AcceptButton>
          </div>
        </Column>
        <div className={styles.infoSection}>
          {/* <InfoCircle className={styles.infoIcon} /> */}
          <p className={styles.infoText}>
            <FormattedMessage
              id="avatar-settings-content.info-text"
              defaultMessage="Your avatar represents you in the virtual space. You can customize it further once you're inside."
            />
          </p>
        </div>
      </div>
    </div>
  );
}

AvatarSettingsContent.propTypes = {
  className: PropTypes.string,
  displayName: PropTypes.string,
  pronouns: PropTypes.string,
  displayNameInputRef: PropTypes.func,
  pronounsInputRef: PropTypes.func,
  disableDisplayNameInput: PropTypes.bool,
  displayNamePattern: PropTypes.string,
  pronounsPattern: PropTypes.string,
  onChangeDisplayName: PropTypes.func,
  onChangePronouns: PropTypes.func,
  avatarPreview: PropTypes.node,
  onChangeAvatar: PropTypes.func
};
